import "core-js/es/promise";
import $ from "jquery";
import "bootstrap";
import moment from "moment";
import "moment/locale/it.js";

$.ajaxSetup({
    headers: { "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content") },
});

moment.locale("it");
